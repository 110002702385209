.background-actionboard, .background-tribes, .background-team, .background-promovideo{
    background-image: url("../../Assets/sauron.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    flex-shrink: 1;
    width:100%;
    overflow-x:hidden;
}

.background-privacy {
    background-image: url("../../Assets/sauron.png");
    background-size: cover;
    height: 1200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    flex-shrink: 1;
    width:100%;
    overflow-x:hidden;
}

.background-promovideo{
    background-image: url("../../Assets/background-video.png");
}

.background-tribes{
    background-image: url("../../Assets/background-tribes.png");
}

.background-team{
    background-image: url("../../Assets/male_female.png");
}



.outer-actionboard, .outer-tribes, .outer-team, .outer-promovideo, .outer-privacy{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 1200px;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.9);
}

.outer-tribes, .outer-promovideo{
    background:rgba(255, 255, 255, 0.4);
}

.inner-tribe, .inner-teams, .inner-action, .inner-video{
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: space-around;
    align-items:center;
    width: 1400px;
}


.action-board-text, .tribes-text, .team-text, .video-text{
    width: 600px;
}

.img-container, .team-images. .board-image{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 0;
}

.img-container, .team-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 791px;
    align-content: flex-start;
    gap: 0;

}
.team-images {
    flex-basis: 624px;
    justify-content: center;
}

.team-images img{
    padding: 10px;
    height: 300px;
}

.action-board{
    height: 600px;
}

img{
    height: 200px;
}

.outer-privacy {
    padding: 20px;
}

.inner-privacy h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.inner-privacy h3 {
    font-size: 20px;
    color: #007bff;
    margin-top: 20px;
}

.inner-privacy p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 15px;
}

@media (min-width: 100px) and (max-width: 1400px) {

    .background-promovideo, .background-actionboard, .background-tribes, .background-team{
        height: 600px;
    }

    .outer-actionboard, .outer-tribes, .outer-team, .outer-promovideo{
        border-width: 10px;
        height: 600px;
        display: flex;
        flex-direction: column;
    }

    .action-board-text, .tribes-text, .team-text, .video-text{
        width: 200px;

    }

    .inner-action, .inner-tribe, .inner-teams, .inner-video{

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 550px;
    }

    .img-container, .team-images{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-basis: 200px;
        align-content: flex-start;
        gap: 0;
        width: 243px;
    }
    img{
        height: 80px;
    }
    .team-images {
        width: 250px;
    }

    .team-images img{
        height: 120px;
    }

    .action-board{
        height: 250px;
    }

    iframe{
        width: 250px;
        height: 250px;
    }
}



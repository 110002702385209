
.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

/* Navigatielinks styling */
.navbar-links {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.navbar-links li {
    display: inline;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.navbar-links a:hover {
    color: #f0a500;
}


.navbar-button:hover {
    background-color: #d48806;
}

@font-face {
  font-family: "lastAges";
  src: url("../src/Fonts/ACRealAdult.otf");
}

@font-face {
  font-family: "OpenSans";
  src: url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap");
}

@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

*{
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: "Bebas Neue", "Comic Sans MS", sans-serif;
  text-align: center;
  color: white;
}
/*.homepage-container{*/
/*  background-color: red;*/


/*}*/

.homepage{
  background-image: url("../src/Assets/background.jpg");
  background-size: cover;
  height: 1200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

}
.header-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 150px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header h1, .header h2{
  font-family: "Rock Salt", "Bebas Neue", "Comic Sans MS";
  font-size: 80px;
  font-weight: initial;
}

.header h2{
  font-size: 30px;
  font-weight: lighter;

}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

/*form p{*/
/*  font-family: "Bebas Neue", "Comic Sans MS";*/
/*  font-size: 15px;*/
/*}*/

input {
  width: 90%;
  padding: 10px;
  background: none;
  color: white;
  /*font-family: OpenSans;*/
  font-size: 20px;
  border-style: solid;
  border-color: white;
  border-width: 5px;
  border-radius: 10px;
  outline: none;
}

button {
  background: none;
  border: none;
  color: white;
  height: 35px;
  width: 110px;
  margin-top: 10px;
  font-family: "Bebas Neue", "Comic Sans MS";
  font-size: 20px;
  text-decoration: underline;
}

.info-image-container {
  display: flex;
  justify-self: flex-end;
  position: absolute;
  bottom: 100px;

  /*!*position: absolute;*!*/
  /*!*bottom: 5px;*!*/
}

.info-image{
  height: 50px;
}

h1{

}

h2{
font-size: 40px ;
}

h3{
  font-size: 40px ;
  font-size: 25px;
  font-weight: lighter;
  padding-bottom: 10px;
}

.action-board-text h3, .team-text h3{
  color: lightblue;
}

.tribes-text, .video-text{
  color: black;
}

.tribes-text h3, .video-text h3{
  color: white;
}

.action-board{
  height: 700px;
}
p{
  font-size: 25px;
  color: #00ff00;
}

.confirmation{
  font-size: 20px;
  color: white;
}

h4{
  font-size: 20px;
  color: #ff0000;
}

a{
  text-decoration: none;
  color: red;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}





@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (min-width: 100px) and (max-width: 1400px) {

  .homepage{
    height: 650px;
  }
  .header-container {

  }

  .header-container{

  }
  .header h1 {
    font-size: 30px;
  }

  .header h2{
    font-size: 15px;
  }
  .bottom-container{
    padding-bottom: 5px;
  }

  h1{
    font-size: 20px;
  }

  h2 {
    font-size: 15px;
  }
  h3{
    font-size: 15px;
  }

  .confirmation, .thanks{
    font-size: 20px;
  }

  h4{
    font-size: 15px;
  }

  p{
    font-size: 100px;
  }


  .header-container{
    margin-bottom: 50px;
  }
  .info-image-container{
    bottom: 40px;
    height: 20px;
  }
  .info-image{
    height: 30px;
  }

  form{
    margin-top: 50px;
  }

  .inner-privacy h2{
    font-size: 10px;
    background-color: red;
  }
}


